@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-nav {
  display: flex;
  align-items: center;
}

.lia-nav-white {
  background-color: var(--lia-bs-white);
}

.lia-breadcrumb-container {
  &,
  &-content {
    position: relative;
  }

  &-bg {
    position: absolute;
    inset: 0;
  }
}

.lia-container-bg {
  position: absolute;
  pointer-events: none;
  inset: 0;
}

.lia-crumb {
  &-list {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }
  &-list-item {
    display: flex;
    align-items: center;
    min-width: 0;
  }
  &-item {
    display: block;
    padding: 5px 0;
    margin: 10px 0;
    font-size: var(--lia-font-size-xs);
    font-weight: var(--lia-font-weight-md);
    line-height: var(--lia-bs-line-height-base);
    color: var(--lia-breadcrumb-link-color, var(--lia-bs-text-muted));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a&:hover {
      color: var(--lia-breadcrumb-link-highlight-color, var(--lia-bs-text-muted));
    }
  }
  &-divider {
    display: inline-flex;
    margin: 0 5px;
  }
  &-icon {
    fill: var(--lia-breadcrumb-link-color, var(--lia-local-icon-color));
    opacity: var(--lia-breadcrumb-divider-opacity, 1);

    &-mobile {
      fill: var(--lia-breadcrumb-link-color, var(--lia-local-icon-color));
      margin-right: 5px;
      /* Unable to use flex for alignment because of truncation. */
      margin-top: -3px;
    }
  }
  &-actions {
    display: flex;
    margin-left: auto;

    svg {
      fill: var(--lia-breadcrumb-link-color);
    }
  }

  @media (--lia-breakpoint-up-lg) {
    &-icon-mobile {
      display: none;
    }
  }
  @media (--lia-breakpoint-down-md) {
    &-list-item:not(.lia-crumb-mobile),
    &-divider {
      display: none;
    }
  }
}

.lia-dropdown-toggle {
  display: flex;
  padding: 4px 5px;

  .lia-ellipsis-icon {
    fill: var(--lia-breadcrumb-link-color, var(--lia-local-icon-color));
  }
  &:hover .lia-ellipsis-icon {
    fill: var(--lia-breadcrumb-link-highlight-color, var(--lia-local-icon-color));
  }

  @include default-focus-outline();
}

.lia-editor-action-btn {
  top: 6px !important;
}

.lia-breadcrumb-edit-mode {
  pointer-events: none;
}
